<template>
  <div id="member-memberDetail">
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span>姓名</span>
      </label>
      <el-form-item prop="name">
        <el-input
          disabled
          v-model="props.member.name"
          placeholder="請輸入名稱"
        ></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span>Email</span>
      </label>
      <el-form-item prop="email">
        <el-input
          disabled
          v-model="props.member.email"
          placeholder="請輸入Email"
        ></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span>手機</span>
      </label>
      <div class="flexBox">
        <el-select
          disabled
          class="phoneCountryCode"
          v-model="props.member.phones[0].countryCode"
        >
          <el-option
            v-for="item in countryCodeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-form-item style="width: 100%">
          <el-input
            disabled
            class="phone"
            v-model.number="props.member.phones[0].nationalNumber"
            placeholder="請輸入手機號碼"
          ></el-input>
        </el-form-item>
      </div>
      <!-- <template v-if="props.isGroup">
          <label class="fs-6 fw-bold mb-2">
            <span>部門</span>
          </label>
          <el-form-item>
            <el-select
              v-model="form.groups"
              multiple
              placeholder="請選擇部門"
              style="width: 100%"
            >
              <el-option
                v-for="item in props.groupOptions"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template> -->
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addMember } from "@/core/services/api/members";
import { joinGroup } from "@/core/services/api/groupMember";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["member"],
  setup(props, { emit }) {
    const countryCodeOptions = [
      {
        value: "+886",
        label: "台灣 (+886)",
      },
    ];
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "請填寫Email",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      email: "",
      countryCode: "+886",
      phone: "",
      groups: [],
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        email: "",
        countryCode: "+886",
        phone: "",
        groups: [],
      });
    };

    // onMounted(() => {
    //   let addMemberModal: any = document.getElementById("addMember-modal");
    //   addMemberModal.addEventListener("hidden.bs.modal", function () {
    //     reseatForm();
    //   });
    // });

    return {
      rules,
      formRef,
      form,
      countryCodeOptions,
      props,
    };
  },
});
</script>

<style scoped>
.flexBox {
  display: flex;
}
</style>

<style>
#member-memberDetail .phoneCountryCode input {
  width: 130px;
}

#member-memberDetail .phoneCountryCode .el-form-item {
  width: 300px;
}
</style>