<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addMember-modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-avatar="{ row: tableData }">
          <div class="symbol symbol-55px symbol-circle">
            <img
              v-if="tableData.avatar"
              :src="getImage(tableData.avatar)"
              alt="image"
            />
            <img v-else src="media/avatars/blank.png" alt="image" />
          </div>
        </template>
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-email="{ row: tableData }">
          {{ tableData.email }}
        </template>
        <template v-slot:cell-joinSpaceTime="{ row: tableData }">
          {{ tableData.joinSpaceTime }}
        </template>
        <template v-slot:cell-tableGroup="{ row: tableData }">
          <template
            v-for="(item1, index1) in tableData.tableGroup"
            :key="index1"
          >
            <span :class="`badge-light-primary`" class="badge fw-bold me-1">{{
              item1
            }}</span>
          </template>
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentMember(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#memberDetail-modal"
                  >詳細</el-dropdown-item
                >
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#updateGroups-modal"
                  >修改單位</el-dropdown-item
                >
                <el-dropdown-item @click="handleRemoveSpaceMember()"
                  >移除空間</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 新增成員modal -->
  <div
    class="modal fade"
    id="addMember-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addMember-exampleModalLabel">新增成員</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddMember
            :groupOptions="groupOptions"
            :isGroup="isGroup"
            @onAddMember="onAddMember()"
          ></AddMember>
        </div>
      </div>
    </div>
  </div>
  <!-- 成員詳細資料modal -->
  <div
    class="modal fade"
    id="memberDetail-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="memberDetail-exampleModalLabel">
            成員詳細資料
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <MemberDetail :member="currentMember"></MemberDetail>
        </div>
      </div>
    </div>
  </div>
  <!-- 修改群組modal -->
  <div
    class="modal fade"
    id="updateGroups-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateGroups-exampleModalLabel">
            修改單位
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <UpdateGroups
            :member="currentMember"
            :groupOptions="groupOptions"
            @onUpdateGroups="onUpdateGroups()"
          ></UpdateGroups>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getMembersBySpace,
  removeSpaceMember,
} from "@/core/services/api/members";
import { getGroups } from "@/core/services/api/groups";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { searchItems } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFilePath } from "@/core/utils/file";
import AddMember from "./components/AddMember.vue";
import MemberDetail from "./components/MemberDetail.vue";
import UpdateGroups from "./components/UpdateGroups.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddMember,
    MemberDetail,
    UpdateGroups,
  },
  emits: ["onAddMember", "onUpdateGroups"],
  setup() {
    const currentMember: any = reactive({
      name: "",
      email: "",
      phones: [
        {
          countryCode: "+886",
          nationalNumber: "",
        },
      ],
      group: [],
    });
    const isGroup = ref(true);
    let addMemberModal;
    let updateGroupsModal;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    const tableHeader: any = reactive([
      { key: "avatar", name: "頭像" },
      { key: "name", name: "姓名", sortable: true },
      { key: "email", name: "Email", sortable: true },
      { key: "joinSpaceTime", name: "加入時間", sortable: true },
      { key: "tableGroup", name: "單位", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    let groupsRes: any = [];
    let groupOptions: any = reactive([]);

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const initalGroup = async () => {
      const groupsGuid: any = {
        groups: localstorage.getCurrentSpaces()?.groups,
      };
      if (groupsGuid.groups.length > 0) {
        await getGroups(groupsGuid).then((res) => {
          groupsRes = res;
          let g: any = [];
          for (let i = 0; i < res.length; i++) {
            g.push({
              value: res[i].guid,
              text: res[i].id + "_" + res[i].name,
            });
          }
          groupOptions.splice(0, groupOptions.length, ...g);
        });
      }
    };

    // 取得群組名稱
    const getGroupsData = (groups) => {
      let groupData: any = [];
      groups.forEach((group) => {
        let filterGroup = groupsRes.filter((o) => o.guid == group);
        if (filterGroup.length !== 0) {
          let data = {
            name: filterGroup[0].id + "_" + filterGroup[0].name,
            guid: filterGroup[0].guid,
          };
          groupData.push(data);
        }
      });
      return groupData;
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let members = await getMembersBySpace(currentSpaceGuid!);
      tableData.splice(0);
      members.forEach((o) => {
        tableData.push({
          guid: o.guid,
          avatar: o.theme ? o.theme.avatar : "",
          name: o.name,
          email: o.email,
          tableGroup: getGroupsData(o.groups).map((o) => o.name),
          group: getGroupsData(o.groups),
          joinSpaceTime: getLocalTimeString(o.joinSpaceTime),
          phones: o.phones,
        });
      });

      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

    const getInitials = (email) => {
      return email.slice(0, 1);
    };

    const setTableHeader = () => {
      if (groupsRes.length <= 0) {
        isGroup.value = false;
        let tableHeaderFilter = tableHeader.filter((o) => o.key !== "group");
        tableHeader.splice(0, tableHeader.length, ...tableHeaderFilter);
      }
    };

    const onAddMember = async () => {
      await setTableData();
      addMemberModal.hide();
    };

    const setCurrentMember = (item) => {
      Object.assign(currentMember, item);
      if (currentMember.phones.length == 0) {
        currentMember.phones.push({
          countryCode: "+886",
          nationalNumber: "",
        });
      }
    };

    const onUpdateGroups = async () => {
      await setTableData();
      updateGroupsModal.hide();
    };

    const handleRemoveSpaceMember = async () => {
      await Swal.fire({
        title: "您確定要將成員移除空間嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            space: currentSpaceGuid,
            user: currentMember.guid,
          };
          await removeSpaceMember(request);
          await Swal.fire("移除成功!", "", "success");
          await setTableData();
        }
      });
    };

    onMounted(() => {
      addMemberModal = new Modal(document.getElementById("addMember-modal"));
      updateGroupsModal = new Modal(
        document.getElementById("updateGroups-modal")
      );
    });

    const init = async () => {
      await initalGroup();
      await setTableData();
      setTableHeader();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      isLoadind,
      getImage,
      getInitials,
      search,
      groupOptions,
      isGroup,
      onAddMember,
      setCurrentMember,
      currentMember,
      onUpdateGroups,
      handleRemoveSpaceMember,
    };
  },
});
</script>

<style>
</style>