import ApiService from "@/core/services/ApiService";

// 取得空間使用者
export function joinGroup(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`GroupMember/AddUserBySpace`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得空間使用者
export function removeGroupMember(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`GroupMember/RemoveUserBySpace`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}