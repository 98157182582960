import ApiService from "@/core/services/ApiService";
import { Group } from "@/core/services/responsesModel/group"

// 取得空間群組
export function getGroups(request): Promise<Array<Group>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Groups/Query`,request).then(res => {
            resolve(res.data.groups)
        }).catch(err => {
            reject(err)
        })
    })
}

