
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addMember } from "@/core/services/api/members";
import { joinGroup } from "@/core/services/api/groupMember";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["member"],
  setup(props, { emit }) {
    const countryCodeOptions = [
      {
        value: "+886",
        label: "台灣 (+886)",
      },
    ];
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "請填寫Email",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      email: "",
      countryCode: "+886",
      phone: "",
      groups: [],
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        email: "",
        countryCode: "+886",
        phone: "",
        groups: [],
      });
    };

    // onMounted(() => {
    //   let addMemberModal: any = document.getElementById("addMember-modal");
    //   addMemberModal.addEventListener("hidden.bs.modal", function () {
    //     reseatForm();
    //   });
    // });

    return {
      rules,
      formRef,
      form,
      countryCodeOptions,
      props,
    };
  },
});
