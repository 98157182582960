
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addMember } from "@/core/services/api/members";
import { joinGroup, removeGroupMember } from "@/core/services/api/groupMember";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["member", "groupOptions"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let originalGroups = [];
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      groups: [],
    });

    const submit = async () => {
      await Swal.fire({
        title: "您確定要更新成員群組嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // 群組新增成員
          for (const group of form.groups) {
            if (!originalGroups.includes(group)) {
              let joinGroupRequest = {
                space: currentSpaceGuid,
                user: props.member.guid,
                group: group,
              };
              await joinGroup(joinGroupRequest);
            }
          }
          // 群組移除成員
          for (const group of originalGroups) {
            if (!form.groups.includes(group)) {
              let removeGroupRequest = {
                space: currentSpaceGuid,
                user: props.member.guid,
                group: group,
              };
              await removeGroupMember(removeGroupRequest);
            }
          }
          await Swal.fire("群組已更新!", "", "success");
          emit("onUpdateGroups")
          
        }
      });
    };

    const setGroups = (data) => {
      if (data.length > 0) {
        return data.map((o) => o.guid);
      }
      return [];
    };

    watch(
      props,
      () => {
        form.groups = setGroups(props.member.group);
        originalGroups = setGroups(props.member.group);
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      formRef,
      form,
      submit,
      props,
    };
  },
});
