
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addMember } from "@/core/services/api/members";
import { joinGroup } from "@/core/services/api/groupMember";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["groupOptions", "isGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const value2 = reactive([]);
    const options = [
      {
        value: "+886",
        label: "台灣 (+886)",
      },
    ];
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "請填寫Email",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      email: "",
      countryCode: "+886",
      phone: "",
      groups: [],
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        email: "",
        countryCode: "+886",
        phone: "",
        groups: [],
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增成員嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let addMemberRequest = {
                space: currentSpaceGuid,
                name: form.name,
                email: form.email,
                group: "",
                phones: [
                  {
                    type: "CellPhone",
                    countryCode: form.countryCode,
                    nationalNumber: form.phone.toString(),
                    extension: "",
                    description: "",
                  },
                ],
              };
              let addMemberRes = await addMember(addMemberRequest);
              if (props.isGroup || form.groups.length > 0) {
                for (let index = 0; index < form.groups.length; index++) {
                  let joinGroupRequest = {
                    space: currentSpaceGuid,
                    user: addMemberRes.user.guid,
                    group: form.groups[index],
                  };
                  await joinGroup(joinGroupRequest);
                }
              }
              emit("onAddMember")
              await Swal.fire("新增成功!", "", "success");
            }
          });
        }
      });
    };

    onMounted(() => {
      let addMemberModal: any = document.getElementById("addMember-modal");
      addMemberModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
        formRef.value!.resetFields();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      options,
      value2,
      props,
    };
  },
});
