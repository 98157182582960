<template>
  <div>
    <el-form
      @submit.prevent="submit()"
      :model="form"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span>部門</span>
        </label>
        <el-form-item>
          <el-select
            v-model="form.groups"
            multiple
            placeholder="請選擇部門"
            style="width: 100%"
          >
            <el-option
              v-for="item in props.groupOptions"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addMember } from "@/core/services/api/members";
import { joinGroup, removeGroupMember } from "@/core/services/api/groupMember";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["member", "groupOptions"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let originalGroups = [];
    const formRef = ref<null | HTMLFormElement>(null);
    const form = reactive({
      groups: [],
    });

    const submit = async () => {
      await Swal.fire({
        title: "您確定要更新成員群組嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // 群組新增成員
          for (const group of form.groups) {
            if (!originalGroups.includes(group)) {
              let joinGroupRequest = {
                space: currentSpaceGuid,
                user: props.member.guid,
                group: group,
              };
              await joinGroup(joinGroupRequest);
            }
          }
          // 群組移除成員
          for (const group of originalGroups) {
            if (!form.groups.includes(group)) {
              let removeGroupRequest = {
                space: currentSpaceGuid,
                user: props.member.guid,
                group: group,
              };
              await removeGroupMember(removeGroupRequest);
            }
          }
          await Swal.fire("群組已更新!", "", "success");
          emit("onUpdateGroups")
          
        }
      });
    };

    const setGroups = (data) => {
      if (data.length > 0) {
        return data.map((o) => o.guid);
      }
      return [];
    };

    watch(
      props,
      () => {
        form.groups = setGroups(props.member.group);
        originalGroups = setGroups(props.member.group);
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      formRef,
      form,
      submit,
      props,
    };
  },
});
</script>

