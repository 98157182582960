<template>
  <div id="member-addMember">
    <el-form
      @submit.prevent="submit()"
      :model="form"
      :rules="rules"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">姓名</span>
        </label>
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="請輸入名稱"></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">Email</span>
        </label>
        <el-form-item prop="email">
          <el-input v-model="form.email" placeholder="請輸入Email"></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>手機</span>
        </label>
        <div class="flexBox">
          <el-select class="phoneCountryCode" v-model="form.countryCode">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-form-item style="width: 100%">
            <el-input
              class="phone"
              v-model.number="form.phone"
              placeholder="請輸入手機號碼"
            ></el-input>
          </el-form-item>
        </div>
        <template v-if="props.isGroup">
          <label class="fs-6 fw-bold mb-2">
            <span>部門</span>
          </label>
          <el-form-item>
            <el-select
              v-model="form.groups"
              multiple
              placeholder="請選擇部門"
              style="width: 100%"
            >
              <el-option
                v-for="item in props.groupOptions"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addMember } from "@/core/services/api/members";
import { joinGroup } from "@/core/services/api/groupMember";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["groupOptions", "isGroup"],
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const value2 = reactive([]);
    const options = [
      {
        value: "+886",
        label: "台灣 (+886)",
      },
    ];
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "請填寫Email",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      email: "",
      countryCode: "+886",
      phone: "",
      groups: [],
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        email: "",
        countryCode: "+886",
        phone: "",
        groups: [],
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增成員嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let addMemberRequest = {
                space: currentSpaceGuid,
                name: form.name,
                email: form.email,
                group: "",
                phones: [
                  {
                    type: "CellPhone",
                    countryCode: form.countryCode,
                    nationalNumber: form.phone.toString(),
                    extension: "",
                    description: "",
                  },
                ],
              };
              let addMemberRes = await addMember(addMemberRequest);
              if (props.isGroup || form.groups.length > 0) {
                for (let index = 0; index < form.groups.length; index++) {
                  let joinGroupRequest = {
                    space: currentSpaceGuid,
                    user: addMemberRes.user.guid,
                    group: form.groups[index],
                  };
                  await joinGroup(joinGroupRequest);
                }
              }
              emit("onAddMember")
              await Swal.fire("新增成功!", "", "success");
            }
          });
        }
      });
    };

    onMounted(() => {
      let addMemberModal: any = document.getElementById("addMember-modal");
      addMemberModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
        formRef.value!.resetFields();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
      options,
      value2,
      props,
    };
  },
});
</script>

<style scoped>
.flexBox {
  display: flex;
}
</style>

<style>
#member-addMember .phoneCountryCode input {
  width: 130px;
}

#member-addMember .phoneCountryCode .el-form-item {
  width: 300px;
}
</style>